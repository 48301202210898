.FeedbackModal__modalWrapper {
  max-width: 900px;
  margin: 80px auto;
  height: 86dvh;
}
.FeedbackModal__closeButton {
  position: absolute;
  right: 30px;
  top: 30px;
}
.FeedbackModal__body {
  padding: 40px;
}
.FeedbackModal__subHeadingText {
  letter-spacing: 0.8px;
  font-weight: 500;
  text-transform: uppercase;
}
.FeedbackModal__textBox {
  padding: 20px;
  border: none;
  background-color: #f5f5f5;
}
.FeedbackModal__textBox:focus {
  border: 1px solid #dbd9d9;
}
.FeedbackModal__link {
  font-weight: 300;
  text-decoration: underline;
  color: black;
}
.FeedbackModal__link:hover {
  color: black;
}
.FeedbackModal__icon {
  width: 15px;
  height: 15px;
  margin: 0px 0px 3px 4px !important;
}
.FeedbackModal__lightFont {
  font-weight: 300;
}
.FeedbackModal__fitContent {
  width: fit-content;
  padding-right: 30px;
}
