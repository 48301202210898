.EditBookingBox__editBox {
  border: 1px solid black;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 40px 0;
}
@media (max-width: 670px) {
  .EditBookingBox__editBox {
    flex-direction: column;
    text-align: center;
  }
}
.EditBookingBox__textBox {
  margin-right: 10px;
}

.EditBookingBox__heading {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
}
