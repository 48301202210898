.SecondaryGuestsDropdown___container {
  padding: 0px 0 30px 0;
}

.SecondaryGuestsDropdown__guestsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0 8px 0;
}

.SecondaryGuestsDropdown__addContainer {
  padding: 2px 8px;
  margin-top: 0.5rem;
}
