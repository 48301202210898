.CheckTime__checkTime {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  justify-content: flex-start;
}
.CheckTime__checkIn {
  margin-bottom: 10px;
}

.CheckTime__checkIn,
.CheckTime__checkOut {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.CheckTime__checkIn > span,
.CheckTime__checkOut > span {
  font-weight: 400;
  margin-left: 10px;
}
