.Maintenance__date {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
  margin: 20px 0 5px 0;
}
.Maintenance__header {
  font-size: 26px;
  margin: 30px 0 20px 0;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}
.Maintenance__imagesContainer {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.Maintenance__trainImage {
  height: 'auto';
  max-height: 450px;
  width: 330px;
  object-fit: cover;
}

.Maintenance__poolImage {
  position: relative;
  height: auto;
  max-height: 300px;
  width: 400px;
  object-fit: cover;
  object-position: bottom;
  margin: 200px 0px 0 -20px;
  z-index: -1;
}

@media (max-width: 500px) {
  .Maintenance__imagesContainer {
    padding: 0;
  }
  .Maintenance__imagesContainer > img {
    scale: 0.8;
  }
  .Maintenance__poolImage {
    margin-top: 200px;
    margin-left: -150px;
  }
}

.Maintenance__exploreBelmondImage {
  max-height: 400px;
  width: 100%;
  object-fit: cover;
}

.Maintenance__description {
  line-height: 23px;
  margin-bottom: 15px;
}

.Maintenance__pictureTextBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
