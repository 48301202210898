.Footer__bbeFooter {
  padding-bottom: 50px;
}
.FooterMobile__section {
  border-bottom: 1px solid #ebebeb;
  margin: 10px;
}

.FooterMobile__sectionSelector {
  font-size: 1.4rem;
  line-height: 1.1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  padding-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.FooterMobile__arrowDown::after {
  display: inline-block;
  line-height: 1.1;
  font-family: 'spark-icon-fill', sans-serif;

  font-size: 1.4em;
  vertical-align: baseline;
  font-weight: 500;
  content: '\F10F';
}

.FooterMobile__arrowUp::after {
  display: inline-block;
  line-height: 1.1;
  font-family: 'spark-icon-fill', sans-serif;

  font-size: 1.4em;
  vertical-align: baseline;
  font-weight: 500;
  content: '\F112';
}

.FooterMobile__sectionList ul {
  list-style: none;
  padding: 0;
}

.FooterMobile__sectionList li {
  margin: 0;
  padding-bottom: 10px;
}

.FooterMobile__sectionList li a,
.FooterMobile__sectionList li span {
  color: black;
  text-decoration: none;
  line-height: 1.1;
  font-weight: 300;
  font-size: 1.1em;
}

.FooterMobile__sectionList li span {
  font-weight: 500;
}

.FooterMobile__socialIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  padding-top: 40px;
  padding-bottom: 40px;
}

.FooterMobile__footerText {
  font-size: 10px;
  font-weight: 300;
  line-height: 25px;
  color: #757575;
}
