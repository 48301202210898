.ConfirmationModal__modalWrapper {
  max-width: 530px;
  margin: auto;
  border-radius: 0;
}
.ConfirmationModal__body {
  padding: 20px;
}

.ConfirmationModal__content {
  margin: -10px 0 20px 0;
  font-weight: 400;
  width: 100%;
}
.ConfirmationModal__closeButton {
  position: absolute;
  right: 30px;
  top: 30px;
}

.ConfirmationModal__header {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.7px;
}

.ConfirmationModal__buttons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.ConfirmationModal__buttons > button {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: auto;
}

@media (max-width: 485px) {
  .ConfirmationModal__buttons > button {
    width: 100%;
  }
}
