.FilterOptions__title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 20px;
}
.FilterOptions__column {
  border-bottom: 1px solid #dbd9d9;
}

.FilterOptions__container {
  margin: 10px 0;
}
.FilterOptions__row > div {
  padding: 0px 12px;
}

.FilterOptions__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .FilterOptions__buttons > button {
    width: auto;
  }
}
