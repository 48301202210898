.celoPay {
  border: 1px solid #c9cdd3;
  border-radius: 10px;
  padding: 15px;
  background-color: #f7f7f8;
}

.celoPay > form > div > * > iframe {
  min-height: 40px !important;
}
.creditCardIcon {
  box-shadow: 0px 1px 2px rgb(215, 215, 215);
  border-radius: 4px;
  margin-right: 10px;
}

.paymentContainerHeading {
  font-size: 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.headingParagraph {
  font-size: 12px;
  color: #00112c;
  font-weight: 400;
  margin: 20px 0;
}

.middleRowFields {
  margin-top: 15px;
  margin-bottom: 20px;
}
.middleRowFields > div > div > iframe {
  min-height: 36px;
}
.fieldDescription {
  font-size: 12px;
  color: #00112c;
  font-weight: 400;
  margin-top: -2px;
}
