.SelectRoomMobilePicker__container {
  position: relative;
}

.SelectRoomMobilePicker__main {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-y: auto;
}

.SelectRoomMobilePicker__header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

.SelectRoomMobilePicker__heading {
  color: #000;
  font-size: 1.16rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: 1.7px;
  margin: 0.75rem 1rem;
}
.SelectRoomMobilePicker__close {
  position: absolute;
  right: 10px;
}
.SelectRoomMobilePicker__close::before {
  display: inline-block;
  line-height: 1em;
  font-family: 'spark-icon-line', sans-serif;
  font-size: 15px;
  vertical-align: baseline;
  font-weight: normal;
  margin-right: 1rem;
  content: '\F123';
}

.SelectRoomMobilePicker__body {
  position: relative;
  padding: 20px;
}
.SelectRoomMobilePicker__section {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 0;
  width: 100%;
  background-color: 'green';
  border-bottom: 1px solid #dbd9d9;
  text-transform: uppercase;
}
.SelectRoomMobilePicker__open {
  border-bottom: 1px solid black;
}
