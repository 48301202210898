.RoomRate__container {
  width: 100%;
}

.RoomRate__desktopView {
  display: block;
}

.RoomRate__mobileView {
  display: none;
}

.RoomRate__roomRate > ul {
  font-weight: 300;
}

/* Common button styles for both desktop and mobile */
.RoomRate__roomRate > div > button,
.RoomRate__mobileActions button {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.75;
  letter-spacing: 1px;
}

.RoomRate__roomPrice {
  display: flex;
  justify-content: flex-end;
}

.RoomRate__roomPriceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-wrap: nowrap;
}

.RoomRate__price {
  font-size: 26px;
  margin: 4px 0;
  display: flex;
  justify-content: flex-end;
}

.RoomRate__commissionContainer {
  padding: 10px 5px;
  border: 1px solid #55a04b;
  margin: 15px 0;
  font-weight: 300;
  letter-spacing: 0.7px;
  display: flex;
  align-items: center;
}

.RoomRate__description {
  font-weight: 400;
}

.RoomRate__icon {
  margin: 0 10px 0 8px;
}

.RoomRate__buttons {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
  width: 100%;
}

.RoomRate__buttons > div + div {
  margin-left: 10px;
}

/* Mobile Styles */
@media (max-width: 750px) {
  .RoomRate__desktopView {
    display: none;
  }

  .RoomRate__mobileView {
    display: block;
  }

  .RoomRate__mobileContainer {
    display: flex;
    flex-direction: column;
  }

  .RoomRate__mobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .RoomRate__mobileDetails {
    width: 100%;
  }

  .RoomRate__mobileActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 16px;
  }

  .RoomRate__mobileNightInfo {
    font-size: 14px;
  }

  .RoomRate__buttons {
    display: block;
    margin: 15px 0;
  }

  .RoomRate__buttons > div {
    width: 100%;
  }

  .RoomRate__buttons > div + div {
    margin-left: 0;
    margin-top: 10px;
  }

  .RoomRate__buttons button {
    width: 100%;
    min-width: 100%;
  }
}
