.RoomRateModal__modalBody {
  padding: 30px;
}

.RoomRateModal__modalWrapper {
  max-width: 1000px;
  margin: 70px auto 0 auto;
}

@media screen and (max-width: 768px) {
  .RoomRateModal__modalWrapper {
    height: 85dvh;
  }

  .RoomRateModal__modalBody {
    padding: 10px;
  }
}

.RoomRateModal__rightArrow::after {
  display: inline-block;
  line-height: 1em;
  font-family: 'spark-icon-fill', sans-serif;
  font-size: 1em;
  margin-left: 4px;
  vertical-align: baseline;
  font-weight: normal;
  content: '\F111';
}

.RoomRateModal__rightBody {
  padding: 20px;
  background-color: #faf9f7;
}

.RoomRateModal__tableRows > div {
  border-bottom: 1px solid #dbd9d9;
}

.RoomRateModal__priceRow {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-bottom: 15px;
}

.RoomRateModal__priceRowNights {
  display: flex;
  justify-content: space-between;
  background-color: #f8f7f5;
}

.RoomRateModal__coffeIcon {
  font-weight: 500;
  margin-bottom: 1rem;
}
.RoomRateModal__coffeIcon::before {
  display: inline-block;
  line-height: 1em;
  font-family: 'spark-icon-fill', sans-serif;

  font-size: 1em;
  vertical-align: baseline;
  font-weight: normal;
  margin-right: 5px;
  content: '\F179';
}
.RoomRateModal__totalPrice {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding-top: 15px;
}
.RoomRateModal__taxGBP {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 25px;
}
.RoomRateModal__buttonRow {
  display: flex;
  justify-content: flex-end;
}
.RoomRateModal__closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
}
.RoomRateModal__nightlyRates {
  margin-top: -10px;
  padding-bottom: 15px;
}
