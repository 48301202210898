.MultipleGuestForm__sectionContainer {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 15px 25px;
  border: 1px solid #dbd9d9;
}

@media (max-width: 767px) {
  .MultipleGuestForm__sectionContainer {
    padding: 15px 15px 10px 15px;
  }
}

.MultipleGuestForm__sectionHeading > span {
  display: flex;
  justify-content: flex-end;
}

.MultipleGuestForm__sectionHeading > h2 {
  font-size: 14px;
  margin-bottom: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 17px;
  letter-spacing: 1px;
  margin-top: 20px !important;
}

.MultipleGuestForm__formGroup {
  position: relative;
}

.MultipleGuestForm__bbeFormControl {
  border: 1px solid #776e6e;
  background: #f5f5f5;
  border-radius: 0px;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  padding: 5px;
  line-height: 1.5rem;
}

.MultipleGuestForm__bbeFormControl:focus {
  background-color: #f5f5f5;
}

.MultipleGuestForm__inputText:focus ~ .MultipleGuestForm__floatingLabel,
.MultipleGuestForm__inputText:not(:placeholder-shown)
  ~ .MultipleGuestForm__floatingLabel {
  top: 3px;
  font-size: 11px;
}

.MultipleGuestForm__floatingLabel {
  position: absolute;
  pointer-events: none;
  top: 14px;
  left: 0.75rem;
  font-weight: 300;
  font-size: 12px;
  transition: 0.2s ease all;
}

.MultipleGuestForm__inputText {
  padding-top: 1.5rem;
  border: 2px solid #f5f5f5;
  border-bottom: 1px solid #776e6e;
}

.MultipleGuestForm__inputText:focus {
  border: 2px solid #776e6e;
  border-color: #000 !important;
  box-shadow: none !important;
}

.MultipleGuestForm__inputText:hover {
  border-bottom: 2px solid #776e6e;
}

.MultipleGuestForm__requiredBlack {
  font-family: arial, sans-serif;
  font-size: 19px;
  line-height: 1;
  margin-left: 5px;
  font-weight: normal;
  vertical-align: middle;
  margin-right: 5px;
}

.MultipleGuestForm__collapse {
  display: none;
}

.MultipleGuestForm__heading {
  display: flex;
  align-items: baseline;
  font-size: 26px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.MultipleGuestForm__roomNumerator {
  font-size: 12px;
  font-weight: 300;
  text-transform: none;
  margin-left: 15px;
}

@media (max-width: 767px) {
  .MultipleGuestForm__heading {
    flex-direction: column;
    align-items: flex-start;
  }
  .MultipleGuestForm__roomNumerator {
    margin-left: 0px;
  }
}

.MultipleGuestForm__boldText {
  font-weight: 600;
  margin: 0 5px 0 5px;
}
.MultipleGuestForm__dashedHr {
  border-top: 1px dashed #a2a2a2;
  margin-bottom: 30px;
  background-color: transparent;
}
