.policy {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.policy > h6 {
  font-weight: 600;
  letter-spacing: 0.4px;
}

.policy > span {
  margin-top: 0.3rem;
}
