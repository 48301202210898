.FilterDropdown__boxCollapsed {
  border-bottom: 1px dashed #dbd9d9;
  padding: 20px;
  cursor: pointer;
}
.FilterDropdown__activeFilters {
  background-color: #faf9f7;
}
.FilterDropdown__title {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
