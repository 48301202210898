.BookingsCart__cartContainer {
  background: #faf9f7;
  border-radius: 0px;
  z-index: 1;
  border: 1px solid #ffffff !important;
  padding: 1.5rem !important;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
}
.BookingsCart__closeButton {
  position: fixed;
  right: 34px;
}

.BookingsCart__roomDetails {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1rem;
}

.BookingsCart__nightsButton {
  text-decoration: underline;
  cursor: pointer;
}

.BookingsCart__totalPrice {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-top: 1rem;
}

.BookingsCart__totalPrice > span {
  font-size: 15px;
}

.BookingsCart__tax {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
}

.BookingsCart__addRoomButton {
  margin: 2rem 0;
  cursor: pointer;
  text-decoration: underline;
}

.BookingsCart__addRoomButton:active {
  color: green;
}

.BookingsCart__componentSubheading {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.4;
  padding: 3px 0;
  letter-spacing: 0.5px;
  width: 100%;
}

.BookingsCart__nextRoomDates {
  display: flex;
  flex-direction: column;
}

.BookingsCart__roomSeparator {
  background-color: #ffffff;
  padding: 12px 20px 0px 20px;
  border: 1px solid #dbd9d9;
  margin-bottom: 20px;
}
.BookingsCart__roomCollapsed {
  background-color: #f6f4f0 !important;
}

.BookingsCart__nextRoomContainer {
  position: relative;
  background-color: #fff;
  border: 1px solid #dbd9d9;
  border-left: 5px solid #000000;
  padding: 12px 15px 0px 15px;
  margin-bottom: 20px;
}

.BookingsCart__taxes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.BookingsCart__nextRoomContainer::after,
.BookingsCart__editRoomContainer::after {
  display: inline-block;
  line-height: 1em;
  font-family: 'spark-icon-fill', sans-serif;
  font-size: 1em;
  vertical-align: baseline;
  font-weight: normal;
  content: '\F117';
}

.BookingsCart__nextRoomContainer::after,
.BookingsCart__editRoomContainer::after {
  position: absolute;
  font-size: 26px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #000000;
  left: -20px;
}

.BookingsCart__editRoomContainer {
  position: relative;
  background-color: #fff;
  border: 1px solid #dbd9d9;
  border-left: 5px solid #000000;
  border-right: none;
  margin: 0rem -1rem;
  padding: 0rem 1rem;
}

.BookingsCart__addProduct {
  margin-top: 1rem;
}

.BookingsCart__addProduct > button {
  border-bottom: 1px solid #b8bbbf;
}

.BookingsCart__addProduct > span:hover {
  border-bottom: 2px solid #b8bbbf;
}

.BookingsCart__headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

.BookingsCart__headerContainer > div > svg {
  margin-bottom: 8px;
}

.BookingsCart__bookingConfirmationNumber {
  font-weight: 500;
  color: #609219;
}

.BookingsCart__roomNumerator {
  text-transform: none;
  font-weight: 300;
  font-size: 12px;
  color: #757575;
  margin-right: 10px;
  text-wrap: nowrap;
}

.BookingsCart__boldPrice {
  font-weight: 600;
}

.BookingsCart__priceRow {
  padding: 15px 5px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.BookingsCart__alternateCurrency {
  background-color: white;
  border: 1px solid black;
  padding: 15px;
}
