.AddressForm__required {
  color: #ac0000;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1;
  margin-left: 5px;
  font-weight: normal;
  vertical-align: middle;
}
.AddressForm__icon::after {
  position: absolute;
  z-index: 100;
  content: url('../../assets/images/magnifiying-glass.png');
  height: 8px;
  width: 8px;
  right: 20px;
  top: 20px;
}
