.LoadingPaymentModal__modalWrapper {
  max-width: 530px;
  margin: auto;
  border-radius: 0;
}

.LoadingPaymentModal__text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 70px;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(
    90deg,
    #000 0%,
    #000 35%,
    rgba(180, 180, 180, 0.4) 50%,
    #000 65%,
    #000 100%
  );
  background-size: 300% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: shimmer 4.5s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}

.LoadingPaymentModal__animation {
  background: white;
  position: relative;
}
