.BookingActionButtonsModal__tool {
  letter-spacing: 0.5px;
}

.BookingActionButtonsModal__header {
  font-weight: 600;
  font-size: 14px;
  margin: 15px 0;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}

.BookingActionButtonsModal__body {
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.BookingActionButtonsModal__inputContainer {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 20px;
}

.BookingActionButtonsModal__inputRadio {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 5px;
}

.BookingActionButtonsModal__inputCheckbox {
  flex-shrink: 0;
  border-radius: 0px !important;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 25px;
  border-color: #979797;
  transform: rotate(45deg);
}

.BookingActionButtonsModal__inputCheckbox:focus,
.BookingActionButtonsModal__inputCheckbox::selection {
  background-color: #fff;
  border: 2px solid #776e6e;
  box-shadow: none;
}

.BookingActionButtonsModal__inputCheckbox:checked {
  background-image: none !important;
  background-color: #fff;
  border: 1px solid #979797;
}

.BookingActionButtonsModal__inputCheckbox:checked::after {
  content: '';
  position: absolute;
  transition: 'fade-in';
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  background-color: black;
}
.BookingActionButtonsModal__inputCheckbox:checked:focus {
  background-color: #fff;
  border: 1px solid #555252;
  box-shadow: none;
}

.BookingActionButtonsModal__modalText {
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 4px;
  text-align: left;
}

.BookingActionButtonsModal__confirmListing {
  display: flex;
  flex-direction: column;
}
.BookingActionButtonsModal__modalWrapperLarge {
  max-width: 1000px;
  margin: auto;
}
