.PriceRangeSlider__boxExpanded {
  border: 1px dashed #dbd9d9;
  margin: 10px 0px;
  padding: 20px;
}
.PriceRangeSlider__boxCollapsed {
  border-bottom: 1px dashed #dbd9d9;
  padding: 20px;
}

.PriceRangeSlider__inputField {
  display: inline-flex;
  flex-direction: column;
}
.PriceRangeSlider__activeFilters {
  background-color: #faf9f7;
}

.PriceRangeSlider__inputField {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}
.PriceRangeSlider__inputField > div > input {
  height: 25px;
  width: 50px;
  padding: 8px 0 8px 10px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #dbd9d9;
}

.PriceRangeSlider__inputField > input:focus {
  border-bottom: 2px solid #dbd9d9;
  box-shadow: none;
}
.PriceRangeSlider__inputField > label {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
.PriceRangeSlider__title {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.PriceRangeSlider__slider {
  width: 90%;
  padding: 20px 0 10px 0;
  margin: 0px 0 30px 10px;
}
