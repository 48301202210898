.ImageCarousel__carousel {
  display: flex;
  height: 100%;
}
.ImageCarousel__carousel > div > div {
  height: 100%;
}
.ImageCarousel__carousel > div > div > ul {
  height: 100%;
}
.ImageCarousel__carousel > div > div > ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.ImageCarousel__imageContainer {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100% !important;
  height: 100%;
}
.ImageCarousel__imageContainer > div {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f6f4f0;
}
.ImageCarousel__prevArrow {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  cursor: pointer;
}
.ImageCarousel__nextArrow {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  cursor: pointer;
}
.ImageCarousel__nextArrow > button,
.ImageCarousel__prevArrow > button {
  padding: 5px 10px;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid grey;
}
.ImageCarousel__nextArrow > button:focus,
.ImageCarousel__prevArrow > button:focus {
  border: 2px solid black;
}
.ImageCarousel__nextArrow > button:hover,
.ImageCarousel__prevArrow > button:hover {
  background-color: white;
}
