.InputComponent__formGroup {
  position: relative;
  width: 100%;
}

.InputComponent__bbeFormControl {
  height: 25px;
  width: 100%;
  padding: 12px 20px 12px 0;
  border: none;
  margin-top: 5px;
  border-bottom: 1px solid black;
  border-radius: 0;
}

.InputComponent__label {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  font-size: 14px;
}

.InputComponent__inputText:focus {
  border-bottom: 2px solid black;
}

.InputComponent__inputTextError:focus {
  border-color: #ac0000;
  box-shadow: none !important;
}

.InputComponent__errorText {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: #d0011b;
  padding: 5px;
  font-weight: 400;
}
.InputComponent__errorIcon {
  position: absolute;
  bottom: 8px;
  right: 5px;
}
