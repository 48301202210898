.SpecialCode__dropdownTitle {
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 0 5px;
  text-transform: uppercase;
}

@media (max-width: 990px) {
  .SpecialCode__container {
    padding-top: 10px;
    width: 100%;
  }
  .SpecialCode__container > div > button {
    margin: 15px 0 0 0;
  }
}
.SpecialCode__crossIcon {
  position: absolute;
  right: 6px;
  top: 28px;
  cursor: pointer;
}
