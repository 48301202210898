.SingleGuestForm__sectionContainer {
  position: relative;
  height: 100%;
}
.SingleGuestForm__formGroup {
  position: relative;
}
.SingleGuestForm__overlay {
  background-color: grey;
  opacity: 0.05;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.SingleGuestForm__label {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.SingleGuestForm__input {
  width: 100%;
  padding: 8px 8px 8px 8px;
  border: none;
  border: 1px solid black;
  height: 120px;
}

.SingleGuestForm__bbeFormControl:focus {
  border-bottom: 2px solid black;
}
.SingleGuestForm__sectionHeading > h2 {
  text-transform: uppercase;
  line-height: 28px;
  margin-top: 20px !important;
}
