.RoomTypeModal__modalWrapper {
  max-width: 1280px;
  margin: 70px auto 0 auto;
  height: 85dvh;
  overflow: hidden;
}
.RoomTypeModal__modalBody {
  margin: 20px;
}
.RoomTypeModal__closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
}
.RoomTypeModal__headingRow {
  background-color: #faf9f7;
}
.RoomTypeModal__heading {
  display: flex;
  flex-direction: column;
  padding: 10% 15%;
}
.RoomTypeModal__heading > h1 {
  margin-left: -2px;
}
.RoomTypeModal__heading > h2 {
  margin-bottom: 0px;
  padding-bottom: 10px;
}
.Room__roomDetailsModal > span:not(:last-child)::after {
  content: '|';
  margin-left: 0.75rem;
}
.Room__roomDetailsModal > span {
  margin-right: 0.75rem;
}
.Room__roomDetailsModal {
  padding: 0 0 60px 0;
}
.RoomTypeModal__fineText {
  font-family: 'Theano Didot', serif;
  padding: 30px 0;
  font-size: 23px;
  letter-spacing: 1px;
  text-align: center;
}
.RoomTypeModal__features {
  padding: 20px 0px;
}
.RoomTypeModal__features > h2 {
  margin: 0px;
  padding-bottom: 15px;
}
.RoomTypeModal__list > li {
  margin-bottom: 10px;
}

.RoomTypeModal__listColumns {
  column-count: 2;
  column-gap: 25px; /* Adjust the gap between columns as needed */
}

@media (max-width: 800px) {
  .RoomTypeModal__heading {
    padding: 20px 20px;
  }
  .RoomTypeModal__heading > h1 {
    font-size: 26px;
  }
  .RoomTypeModal__detailsRow {
    display: flex;
    flex-direction: column-reverse;
  }
  .RoomTypeModal__modalBody h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: 0.7px;
    padding-bottom: 10px;
  }
  .Room__roomDetailsModal {
    padding: 0 0 10px 0;
  }
  .RoomTypeModal__listColumns {
    column-count: 1;
  }
}
.RoomTypeModal__column {
  padding: 0 !important;
  height: 420px;
}

@media (max-width: 1147px) {
  .RoomTypeModal__column {
    height: 440px;
  }
}
