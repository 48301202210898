/* General Styles */

.minimum-price {
  font-size: 9px;
  color: black;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CalendarMonth_caption {
  text-transform: uppercase;
}

.DayPicker_weekHeader_li_1 > small {
  font-size: 13px !important;
}

/* Calendar Day Default Styles */

.CalendarDay__default {
  color: black;
  border: none;
  padding: 0;
}

.CalendarDay__default:hover {
  border: none !important;
  background-color: black;
}

.CalendarDay__default:hover > div > div > div {
  color: white;
}

.CalendarDay__default:hover > div > div > .minimum-price {
  color: white;
}

.CalendarDay__default:hover.CalendarDay__blocked_calendar {
  background-color: white;
}

/* Calendar Day Selected Styles */

.CalendarDay__selected {
  background-color: black;
  color: white !important;
  padding: 0px;
}

.CalendarDay__selected > div > div > div {
  color: white !important;
}

.CalendarDay__selected > div > div > .minimum-price {
  color: white !important;
}

.CalendarDay__selected:hover {
  background-color: black !important;
}

.CalendarDay__selected > .checkOutOnly > .clipPathDiv {
  display: none;
}

.CalendarDay__selected > .disabledDate {
  background-color: inherit;
}

/* Calendar Day Selected Span Styles */

.CalendarDay__selected_span {
  background-color: #b9b9b9 !important;
  color: black !important;
}

.CalendarDay__selected_span > div > div {
  color: black !important;
}

.CalendarDay__selected_span > div > div > .minimum-price {
  color: black !important;
}

.CalendarDay__selected_span:hover {
  background-color: black !important;
}

.CalendarDay__selected_span:hover > div > div {
  color: white !important;
}

.CalendarDay__selected_span:hover > div > div > .minimum-price {
  color: white !important;
}

/* TODO: TEMP-CALENDAR-FIX */
/* .CalendarDay__selected_span > .disabledPickedDay > div > .minimum-price {
  color: white !important;
} */

/* Calendar Day Hovered Span Styles */

.CalendarDay__hovered_span {
  background-color: #b9b9b9;
}

.CalendarDay__hovered_span.CalendarDay__blocked_calendar {
  background-color: #b9b9b9 !important;
}

.CalendarDay__hovered_span.CalendarDay__blocked_calendar > div > div {
  color: black !important;
}

.CalendarDay__hovered_span.CalendarDay__blocked_calendar
  > div
  > div
  > .minimum-price {
  color: black !important;
}

.CalendarDay__hovered_span > div > div > .minimum-price {
  color: black;
}

/* hovering whilst selecting */

.CalendarDay__hovered_span:hover > div > div {
  color: white;
}

/* Calendar Day Blocked Calendar Styles */

.CalendarDay__blocked_calendar {
  background-color: white;
}

.CalendarDay__blocked_calendar > div > div > div {
  color: #ccc;
}

/* unless its in a selected or hovered span, then make text black */
.CalendarDay__blocked_calendar.CalendarDay__selected_span > div > div > div {
  color: black;
}

.CalendarDay__blocked_calendar.CalendarDay__hovered_span > div > div > div {
  color: black;
}

.CalendarDay__blocked_calendar:hover > div > div > div {
  color: #ccc !important;
}

.CalendarDay__blocked_calendar > div > div > .minimum-price {
  color: #ccc;
}

/* set some different styles if the day is blocked, but exists in a selected span */

.CalendarDay__blocked_calendar.CalendarDay__selected_span:hover {
  background-color: #b9b9b9 !important;
}

.CalendarDay__blocked_calendar.CalendarDay__selected_span:hover > div > div {
  color: dave !important;
}

.CalendarDay__blocked_calendar.CalendarDay__selected_span:hover
  > div
  > div
  > .minimum-price {
  color: black !important;
}

.CalendarDay__blocked_calendar:hover > div > div > .minimum-price {
  color: #ccc !important;
}

.CalendarDay__blocked_calendar.CalendarDay__hovered_span {
  color: black;
  background-color: white;
}

/* Calendar Day Blocked Out of Range Styles */

.CalendarDay__blocked_out_of_range {
  color: grey;
}

.CalendarDay__blocked_out_of_range > div > div {
  color: grey !important;
}

/* Disabled Day Styles */

/* TODO: TEMP-CALENDAR-FIX */
/* .disabledPickedDay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #d0011b;
  color: white;
}

.disabledPickedDay > div {
  color: white !important;
}

*/

.disabledDate {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f6f4f0;
  border: none;
}

/* TODO: TEMP-CALENDAR-FIX */
.disabledDate:hover {
  background-color: black;
}

.disabledDate.noSelectHighlight:hover {
  background-color: #f6f4f0;
}

/* Checkout Only Styles */

.checkOutOnly {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;
}

.checkOutOnly > div {
  z-index: 1;
}

.checkOutOnly > .clipPathDiv {
  position: absolute;
  inset: 0;
  clip-path: polygon(100% 0, 100% 100%, 50% 50%);
  background-color: #f6f4f0;
}

/* TODO: TEMP-CALENDAR-FIX */
.checkOutOnly:hover > .clipPathDiv {
  display: none;
}

/* Media Queries */

@media (max-width: 750px) {
  .CalendarDay {
    font-size: 13px !important;
  }
}

/* Miscellaneous Styles */

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid grey;
}

td > div > div {
  font-size: 14px;
}

/* always hide the default */
.DayPickerNavigation_button__horizontalDefault {
  display: none;
}
