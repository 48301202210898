.PaymentInformationModal__container {
  margin: 20px 0;
}
.PaymentInformationModal__list > li {
  margin-bottom: 20px;
}
.PaymentInformationModal__modalWrapper {
  max-width: 1000px;
  margin: 70px auto 0 auto;
}
.PaymentInformationModal__closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
}
