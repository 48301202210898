.RoomRatesListItem__container {
  margin: 0 0 70px 0;
}
.RoomRatesListItem__heading {
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.RoomRatesListItem__divider {
  border-top: 0.5px solid black;
  opacity: 1;
  margin: 20px 0 0 0;
}
.RoomRatesListItem__hideButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: -20px;
  padding-bottom: 30px;
}
