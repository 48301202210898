.SignUpFormConsents__infoText {
  font-size: 11px;
  margin: 20px 0;
}
.SignUpFormConsents__checkboxText {
  font-size: 11px;
  margin-left: 10px;
}
.SignUpFormConsents__checkboxText > a {
  font-size: 11px;
  color: black;
}
