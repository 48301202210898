.Currency__modalWrapper {
  max-width: 500px;
  margin: 80px auto;
  height: 86dvh;
  overflow-y: hidden;
  border-radius: 0;
}
.Currency__closeButton {
  position: absolute;
  right: 20px;
  top: 30px;
}
