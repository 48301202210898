.SlidingMenu__wrapper {
  position: relative;
  background-color: rgb(255, 255, 255);
}
.SlidingMenu__wrapper:not(:focus-within) {
  background-color: rgb(255, 255, 254);
  transition: background-color 0.01s;
}

@media (max-width: 620px) {
  .SlidingMenu__right {
    width: 100%;
  }
}
@media (min-width: 620px) {
  .SlidingMenu__right {
    width: 450px;
  }
}

.SlidingMenu__right {
  overflow-y: auto;
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  transition: 0.4s;
  background: white;
  z-index: 1050;
  transform: translateX(110%);
  box-shadow: 0 4px 9px 3px rgb(0 0 0 / 30%);
}

.SlidingMenu__main {
  position: fixed;
  min-width: 360px;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transition: 0.4s;
  background: white;
  z-index: 1050;
  transform: translateX(-110%);
  box-shadow: 0 4px 9px 3px rgb(0 0 0 / 30%);
}

.SlidingMenu__main.active,
.SlidingMenu__right.active {
  transform: translateX(0);
}

.SlidingMenu__closeButton {
  z-index: 1;
  position: absolute;
  right: 0;
  margin: 20px;
}

.SlidingMenu__closeButton svg {
  width: 25px;
  height: 25px;
}

.SlidingMenu__hotelName {
  padding: 0 1rem 0 1rem;
  font-size: 1.16rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 1.7px;
}

.SlidingMenu__overlay {
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1021;
  height: 100%;
  width: 100%;
}
