.RoomCategoriesListItem__ratesExpanded {
  margin-bottom: 50px;
  padding: 10px;
  background-color: #faf9f7;
}

.RoomCategoriesListItem__ratesCollapsed {
  padding: 10px;
  background-color: white;
}

.RoomCategoriesListItem__ratesContainer {
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .RoomCategoriesListItem__ratesContainer {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin-bottom: 20px;
  }

  .RoomCategoriesListItem__ratesExpanded {
    margin-top: 7px;
    padding: 10px 20px;
  }
  .RoomCategoriesListItem__ratesCollapsed {
    padding: 10px 20px;
  }

  .RoomCategoriesListItem__ratesContainer > div {
    width: 58.6%;
  }
}
