.SelectRoomPicker__select {
  height: 26px;
  width: 100%;
  padding: 0 0 5px 0;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid black;
  color: black;
  pointer-events: none;
}

.SelectRoomPicker__select:disabled {
  background-color: white;
}

.SelectRoomPicker__label {
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  margin-bottom: 4px;
  width: 100%;
  text-align: left;
}

.SelectRoomPicker__specialCodesInput {
  border-bottom: 1px solid #757575;
  color: #757575;
  max-width: 250px;
  min-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.4;
}

.SelectRoomPicker__specialCodesInputFocus > div {
  border-bottom-color: 1px solid black;
  color: black;
}

.SelectRoomPicker__svg {
  position: absolute;
  right: 5px;
  top: 2px;
  color: #757575;
}
.SelectRoomPicker__calendar {
  position: absolute;
  max-width: 900px;
  margin: -21px auto;
  left: 0;
  right: 0;
  padding: 0;
  top: 115px;
  z-index: 1021;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
}
.SelectRoomPicker__calendar:before {
  position: absolute;
  display: inline-block;
  content: '';
  border: 8px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.15);
  top: -16px;
  right: 30%;
}
.SelectRoomPicker__calendar:after {
  position: absolute;
  display: inline-block;
  content: '';
  border: 7px solid transparent;
  border-bottom-color: #fff;
  top: -14px;
  right: 30.1%;
}

.SelectRoomPicker__searchButton {
  display: flex;
  align-items: center;
}
