.CounterInput__input {
  height: 25px;
  width: 25%;
  padding: 8px 0px 8px 0;
  border: none;
  margin-top: 0;
  border-bottom: 1px solid black;
  border-radius: 0;
  background: none;
  text-align: center;
}

.CounterInput__label {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.CounterInput__inputText:focus {
  border-bottom: 2px solid black;
}

.CounterInput__inputTextError:focus {
  border-color: #ac0000;
  box-shadow: none !important;
}

.CounterInput__errorText {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: #d0011b;
  padding: 5px;
  font-weight: 400;
}

.CounterInput__counterButton {
  border: none;
  background: none;
  cursor: pointer;
}
