.NightlyRates__dottedHr {
  border-top: 1px dashed #a2a2a2;
  width: 100%;
  background-color: transparent;
}
.NightlyRates__nightlyRates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}
