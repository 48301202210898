.SelectRoomPicker__select {
  height: 26px;
  width: 100%;
  padding: 0 22px 0 4px;
  font-weight: 400;
  text-align: right;
  border: none;
  border-radius: 0;
}
.SelectRoomPicker__select:focus {
  box-shadow: none;
  border: 1px solid black;
}

.SelectRoomPicker__select::after {
  display: inline-block;
  line-height: 1em;
  font-family: 'spark-icon-fill', sans-serif;

  font-size: 1em;
  vertical-align: baseline;
  font-weight: normal;
  content: '\F10F';
  margin-left: 5px;
}
