.DatePicker_Calendar .rdrDay,
.DatePicker_Calendar .rdrWeekDay {
  height: 40px !important;
  width: 40px !important;
}
.DatePicker_Calendar {
  width: 350px;
}

.DatePicker {
  margin: 0 !important;
}

.DatePicker > button {
  width: 100%;
  text-align: inherit;
  padding: 0;
}

.bbeFormControl {
  height: 25px;
  width: 100%;
  padding: 8px 0 8px 0;
  background-color: white !important;
  border: none;
  margin-top: 20px;
  border-bottom: 1px solid #dbd9d9;
}

.bbeFormControl:focus ~ .floatingLabel,
.bbeFormControl:not(:placeholder-shown) ~ .floatingLabel {
  bottom: 20px;
}

.floatingLabel {
  position: absolute;
  color: #757575;
  font-size: 13px;
  left: 0;
  bottom: 5px;
  margin: 0 0 0 2px;
  transition: 0.3s ease all;
  pointer-events: none;
}

.inputText:focus {
  border-bottom: 2px solid #dbd9d9;
  box-shadow: none !important;
}
.calendarIcon {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #9b9a9a;
}

.DatePicker__errorText {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: #d0011b;
  padding: 5px;
  font-weight: 400;
}
