.SelectHotel__switchMenu {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.SelectHotel__switchMenu > div {
  padding: 0 1rem;
  cursor: pointer;
}

.SelectHotel__switchMenu > div > span {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.7px;
}

.SelectHotel__switchMenuSelected {
  padding-bottom: 0.5rem;
  border-bottom: 2px solid;
}

.SelectHotel__heading {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
