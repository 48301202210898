.RoomImage__closeSliderButton {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
}

.RoomImage__roomImages {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.RoomImage__roomImages:focus {
  outline: 1px solid black;
}

.RoomImage__mainImage {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 0px;
  cursor: pointer;
}

.RoomImage__roomImages.multiple {
  cursor: pointer;
}
.RoomImage__videoPlayer {
  margin-right: 14px;
}
.RoomImage__roomSlider.closed,
.closed {
  display: none;
}

.RoomImage__roomSlider {
  display: flex;
}

.RoomImage__roomSlider > div {
  display: flex;
  align-items: center;
}

.RoomImage__roomTitleContainer {
  position: absolute;
  width: 100%;
  height: 57px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
}

.RoomImage__headerText {
  color: white;
  text-transform: uppercase;
  font-size: 1.33rem;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 1.7px;
  margin-left: 1rem;
}
.RoomImage__tools {
  background-color: #faf9f7;
  width: 100%;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
}
