.BookingsCartRoomRate__container {
  padding: 10px 5px;
  border: 1px solid #dddddd;
  margin: 10px 0;
  font-weight: 300;
  letter-spacing: 0.7px;
  display: flex;
  align-items: center;
  border-color: #55a04b;
}
.BookingsCartRoomRate__description {
  font-weight: 400;
}
.BookingsCartRoomRate__icon {
  margin-left: 10px;
  margin-right: 15px;
}
