.SelectHotelLoader__container {
  display: flex;
  flex-direction: column;
  max-width: 1320px;
  margin: auto;
  padding: 0 0 50px 0;
}

.SelectHotelLoader__title {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.SelectHotelLoader__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.SelectHotelLoader__item {
  display: flex;
  flex-direction: column;
}

.SelectHotelLoader__image_skeleton {
  width: 100%;
}

.SelectHotelLoader__text_skeleton {
  width: 100%;
}

@media (max-width: 991px) {
  .SelectHotelLoader__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 574px) {
  .SelectHotelLoader__grid {
    grid-template-columns: 1fr;
  }
}
