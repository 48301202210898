.SelectComponent__formGroupSelect {
  width: 100% !important;
  position: relative;
}
.SelectComponent__bbeFormSelect {
  height: 26px;
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 0;
  margin-top: 5px;
  border-bottom: 1px solid black;
  appearance: none;
  background-position-x: 100%;
}

.SelectComponent__bbeFormSelect:disabled {
  background-color: #fafafa !important;
}

.SelectComponent__bbeFormSelect:focus {
  box-shadow: none;
  border-bottom: 2px solid black;
}
.SelectComponent__prefixFloat {
  bottom: 20px !important;
}

.SelectComponent__label {
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  font-size: 14px;
}

.SelectComponent__errorText {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: #d0011b;
  padding: 5px;
  font-weight: 400;
}
