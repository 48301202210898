.FilterSection__boxCollapsed {
  cursor: pointer;
}
.FilterSection__hr {
  border-top: 1px dashed #dbd9d9;
  width: 100%;
}
.FilterSection__activeFilters {
  background-color: #faf9f7;
}
.FilterSection__boxExpanded {
  border: 1px dashed #dbd9d9;
  padding: 20px;
  margin: 10px 0px;
}

.FilterSection__title {
  font-size: 14px;
  font-weight: 500;
}

.FilterSection__item {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  font-weight: 400;
}

.FilterSection__item > input[type='radio'] {
  margin-right: 0.5rem;
  accent-color: black;
}

.FilterSection__item > input[type='checkbox'] {
  appearance: none;
  flex-shrink: 0;
  border-radius: 0px !important;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border: 2px solid black !important;
  cursor: pointer;
}

.FilterSection__item > input[type='checkbox']::selection {
  background-color: #fff;
  border: 1px solid #776e6e;
  box-shadow: none;
}

.FilterSection__item > input[type='checkbox']:checked {
  background-image: none !important;
  background-color: #fff;
  border: 1px solid #979797;
}
.FilterSection__item > input[type='checkbox']:checked:focus {
  background-color: #fff;
  border: 3px solid #555252;
  box-shadow: none;
}

.FilterSection__item > input[type='checkbox']:checked::after {
  display: inline-block;
  position: relative;
  left: 6px;
  bottom: 2px;
  width: 5px;
  height: 10px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  transform: rotate(45deg);
  content: '';
}

.FilterSection__label {
  margin: 0;
  cursor: pointer;
  color: black;
  font-weight: 400;
  text-transform: capitalize;
}

.FilterSection__clearButton {
  position: absolute;
  bottom: 0;
  right: -8px;
}
