.BookingsConfirmationActionButtons__heading {
  font-size: 26px;
  text-transform: uppercase;
}
.BookingsConfirmationActionButtons__box {
  background-color: #faf9f7;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.BookingsConfirmationActionButtons__actionButtons {
  display: flex;
  flex-direction: column;
  margin: 5px 0px 5px 0;
}
.BookingsConfirmationActionButtons__iconButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
@media (max-width: 1200px) {
  .BookingsConfirmationActionButtons__iconButtons {
    flex-direction: column;
  }

  .BookingsConfirmationActionButtons__iconButtons > div {
    margin: 5px 0;
  }
}
