.FindReservation__container {
  display: flex;
  max-width: 850px;
  width: 850px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.FindReservation__form {
  max-width: 850px;
}
.FindReservation__containerButton {
  display: flex;
  justify-content: center;
  margin: 25px 0 40px;
}
.FindReservation__dottedLine {
  width: 100%;
  border-top: dotted 1px #dbd9d9;
}
.FindReservation__containerFooter {
  margin: 30px 0;
}
