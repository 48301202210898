.GuestForm__errorText {
  display: flex;
  width: 100%;
  color: #d0011b;
  padding: 5px;
  font-weight: 400;
}

.GuestForm__requiredBlack {
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1;
  font-weight: normal;
  vertical-align: middle;
  margin-right: 5px;
}

.GuestForm__formGroupCheckbox {
  display: flex;
  flex-direction: row;
}

.GuestForm__inputCheckbox {
  appearance: none;
  flex-shrink: 0;
  border-radius: 0px !important;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border: 2px solid black !important;
  cursor: pointer;
}

.GuestForm__inputCheckbox:focus,
.GuestForm__inputCheckbox::selection {
  background-color: #fff;
  border: 1px solid #776e6e;
  box-shadow: none;
}

.GuestForm__inputCheckbox:checked {
  background-image: none !important;
  background-color: #fff;
  border: 1px solid #979797;
}
.GuestForm__inputCheckbox:checked:focus {
  background-color: #fff;
  border: 3px solid #555252;
  box-shadow: none;
}

.GuestForm__inputCheckbox:checked::after {
  display: inline-block;
  position: relative;
  left: 6px;
  bottom: 2px;
  width: 5px;
  height: 10px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  transform: rotate(45deg);
  content: '';
}

.GuestForm__checkboxLink {
  margin-left: 10px;
  text-decoration: underline;
  color: black;
}

.GuestForm__submitRow {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
}

.GuestForm__submitRow > * {
  padding: 0 !important;
}
.GuestForm__roomType {
  font-weight: 600;
  margin-bottom: 6px;
  letter-spacing: 0.4px;
}
.GuestForm__roomType > span {
  margin-left: 10px;
  font-weight: 400;
}
.GuestForm__buttonPadding {
  display: flex;
  justify-content: center;
}
.GuestForm__paymentInformation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}
@media (max-width: 500px) {
  .GuestForm__paymentInformation {
    flex-direction: column;
    align-items: start;
  }
  .GuestForm__paymentInformation > div {
    width: 100%;
  }
}
