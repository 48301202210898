.Currency__currencies {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 500px;
  min-width: 300px;
  list-style: none;
  padding: 0;
}

@media (max-width: 1199px) {
  .Currency__currencies {
    padding-bottom: 35px;
    max-height: 70dvh;
  }
}

.Currency__title {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.Currency__option {
  padding: 8px 12px;
  cursor: pointer;
}

.Currency__option:hover {
  background-color: #000000;
  color: #ffffff;
}

.Currency__input {
  height: 25px;
  width: 100%;
  padding: 8px 0 8px 0;
  border: none;
  margin-top: 20px;
  border-bottom: 1px solid #dbd9d9;
}

.Currency__input:focus {
  border-bottom: 2px solid #dbd9d9;
  box-shadow: none;
}

.Currency__input:focus ~ .Currency_floatingLabel,
.Currency__input:not(:placeholder-shown) ~ .Currency_floatingLabel {
  bottom: 20px;
}

.Currency_floatingLabel {
  position: absolute;
  color: #757575;
  font-size: 13px;
  left: 0;
  bottom: 5px;
  margin: 0 0 0 2px;
  transition: 0.3s ease all;
  pointer-events: none;
}

.Currency__divider {
  padding: 0 12px;
}

.Currency__divider hr {
  border: none;
  height: 1px;
  background-color: #dbd9d9;
  margin: 8px 0;
}
