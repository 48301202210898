.PhoneInput__input {
  height: 25px;
  width: 100%;
  padding: 12px 20px 12px 8px;
  border: none;
  margin-top: 5px;
  border-bottom: 1px solid black;
  border-radius: 0;
}

.PhoneInput__label {
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  font-size: 14px;
}

.PhoneInput__inputTextError:focus {
  border-color: #ac0000;
  box-shadow: none !important;
}

.PhoneInput__errorText {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: #d0011b;
  padding: 5px;
  font-weight: 400;
}

.PhoneInput__error > div,
.PhoneInput__error > input {
  border-color: #d0011b !important;
}

.PhoneInput__container > div {
  border-bottom: 1px solid black;
  margin-right: 0;
}

.PhoneInput__container > div > div {
  color: black !important;
  border-color: black !important;
  margin-left: 7px;
  margin-top: 4px;
}
