.FilterChip__box {
  display: inline-flex;
  justify-self: flex-start;
  align-items: center;
  background-color: #f8f7f5;
  padding: 3px 10px;
  margin: 5px 5px;
  border: 1px solid #dbd9d9;
  border-radius: 16px;
}

.FilterChip__box:focus-within {
  border: 1px solid black;
}
