.TcView__body {
  text-align: center;
  margin: 20px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
}
.TcView__body > p {
  font-size: 14px;
}

.TcView__body > p > b {
  font-size: 16px;
}
