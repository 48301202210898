.NearbyProduct__container {
  display: flex;
  background-color: #faf9f7;
  margin-bottom: 30px;
}

.NearbyProduct__hotelContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px 0 20px 20px;
}

.NearbyProduct__hotelTitle {
  font-size: 26px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.NearbyProduct__location {
  font-weight: 400;
  color: #757575;
  margin-bottom: 10px;
}

.NearbyProduct__priceContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-left: 25px;
  margin: 15px;
  border-left: 1px solid #dbd9d9;
}
.NearbyProduct__price {
  font-size: 26px;
  margin-left: 5px;
}
.NearbyProduct__taxes {
  text-align: right;
  line-height: 22px;
  margin-bottom: 15px;
}

.NearbyProduct__imageContainer {
  width: 30%;
}
.NearbyProduct__imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.NearbyProduct__information {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .NearbyProduct__container {
    flex-direction: column;
  }
  .NearbyProduct__hotelContainer {
    margin: 20px 0 0 0;
  }

  .NearbyProduct__imageContainer {
    width: 100%;
  }

  .NearbyProduct__location {
    margin-bottom: 0px;
  }

  .NearbyProduct__priceContainer {
    width: 100%;
    border-left: none;
    padding: 0;
    margin: 0;
  }

  .NearbyProduct__information {
    flex-direction: column;
  }
}
