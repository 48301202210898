@import-normalize;
/* bring in normalize.css styles */

/* generic classes, filling in the gaps of boostrap */
.fw-600 {
  font-weight: 600;
}

a:hover {
  color: inherit;
}

@font-face {
  font-family: 'spark-icon-line';
  src: url('../fonts/static/font-spark-icon-line.woff2') format('woff2');
}

@font-face {
  font-family: 'spark-icon-fill';
  src: url('../fonts/static/font-spark-icon-fill.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/static/Montserrat-VariableFont_wght.ttf') format('woff2');
}

html {
  height: 100%;
}
:focus-visible {
  outline: 1px solid black;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  height: 100%;
  letter-spacing: 0.5px;
  overflow: auto;
}

* {
  font-size: 13px;
  color: black;
  font-family: Montserrat, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b {
  font-weight: 600;
}

.bbe-hr {
  margin: 0;
  opacity: 0.8;
  color: #dbd9d9;
}
.modal {
  overflow-y: hidden;
}

.modal-dialog {
  max-width: none;
}

.tooltip-inner * {
  color: white;
}
.modal::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(3px);
  pointer-events: none;
}

.cancel-button {
  font-weight: 300;
  color: black;
  font-size: 13px;
}

.card.shadow {
  margin-top: 15px;
  box-shadow: 0 0 0.8rem 0 rgb(0 0 0 / 16%);
  border: 1px solid #fff !important;
}
.btn-close:focus {
  box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 50%);
}
button {
  padding: 0;
  border: none;

  background-color: transparent;
  cursor: pointer;
}

.button,
.button:hover,
.button:focus {
  background: #000000;
  border: 1px solid #b9b9b9;
  color: #ffffff;
  line-height: 1rem;
  font-size: 13px;
  font-weight: 400;
  padding: 0 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 0rem;
  cursor: pointer;
  display: inline-block;
  height: 47px;
  text-align: center;
  white-space: nowrap;
  transition: border-color 0.3s ease-in-out;
}

.button:hover {
  border-color: black;
}
@media (max-width: 767px) {
  .button,
  .button:hover,
  .button:focus {
    width: 100%;
  }
}
.transparent,
.transparent:hover,
.transparent:focus {
  background: transparent !important;
  color: black;
  font-weight: 500;
  border-color: #b9b9b9;
  transition: border-color 0.3s ease-in-out;
}
.transparent:hover {
  border-color: black;
}
.transparent-border {
  border-color: #b9b9b9;
}
.bold,
.bold:hover,
.bold:focus {
  font-weight: 600;
}

.light,
.light:hover,
.light:focus {
  font-weight: 300;
}

.button:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #707070;
  outline: 2px solid black;
}

.button:disabled {
  background-color: #ddd;
  color: #555555;
  border: 1px solid #ddd;
  cursor: not-allowed;
  box-shadow: none;
  opacity: 100;
}
.textButton,
.textButton:hover,
.textButton:focus {
  background: none !important;
  border: none;
  border-radius: 0rem;
  color: black;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0;
}
.normal,
.normal:focus,
.normal:hover {
  font-weight: 500;
}
.textButton:hover {
  text-decoration: none;
  scale: 1.02;
}

.btn:focus {
  box-shadow: none !important;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
    margin-top: 1rem;
  }
  body {
    font-family: 'Montserrat', sans-serif;
  }
}
.pac-container:after {
  background-image: none !important;
  height: 0px;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.hoverEffectButton,
.hoverEffectButton:focus,
.hoverEffectButton:hover {
  background: none !important;
  border: none;
  border-radius: 0rem;
  color: black;
  border-bottom: 1px solid black;
  height: 25px;
  padding: 0;
  letter-spacing: 0.7px;
  font-weight: 500;
}
.hoverEffectButton:hover,
.hoverEffectButton:focus {
  border-bottom: 2px solid black;
}

h1 {
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  padding: 0px 0 20px 0;
  margin: 0;
}

h2 {
  font-size: 26px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding: 0px 0 20px 0;
}
h3 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.7px;
  line-height: 1.4;
  margin-bottom: 0 !important;
  text-transform: uppercase;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 14px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.noBorder {
  border: none !important;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: textfield;
  appearance: textfield; /* Chrome */
}

/* common select arrow styles */
.select-arrow-left {
  background-color: white;
  height: 100%;
  border: none;
  width: 25px;
  padding-left: 0;
  position: absolute;
  left: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 0px 10px -5px rgba(0, 0, 0, 0.3);
  clip-path: inset(0 -100% 0 0);
  z-index: 2;
}

.select-arrow-right {
  background-color: white;
  height: 100%;
  width: 25px;
  padding-right: 0;
  border: none;
  position: absolute;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -3px 0px 10px -5px rgba(0, 0, 0, 0.3);
  clip-path: inset(0 0 0 -100%);
  z-index: 2;
}

.belmond-gray {
  color: #faf9f7;
}

/* list styling */

.diamond-list {
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.diamond-list > div > ul {
  list-style-type: none;
}

.diamond-list li {
  position: relative;
  margin-left: 20px;
  padding-left: 0px;
  margin-bottom: 3px;
}

.diamond-list li::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 10px;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 4px;
  height: 4px;
  background-color: black;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('../assets/images/icons/ArrowDown.svg') !important;
  background-repeat: no-repeat;
  background-position-x: 100% !important;
  background-position-y: 5px;
  background-size: 13px !important;
}

.disableSelectArrow {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  text-indent: 1px;
  text-overflow: '';
  background-image: none !important;
}

.disableSelectArrow::-ms-expand {
  display: none !important;
}

.rc-slider-handle:focus {
  border: 2px solid black !important;
}

.PhoneInput--focus > div {
  border-bottom: 2px solid black !important;
}
.PhoneInput--focus > input {
  border-bottom: 2px solid black !important;
}

/* OVERRIDES */

/* when the app is in french, it sets a width (??????) */
.react-tiny-popover-container {
  width: auto !important;
}
