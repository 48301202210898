.Currency__currencySelected {
  color: black;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1.7px;
}
@media (max-width: 768px) {
  .Currency__currencySelected {
    text-align: left;
  }
}
.Currency__arrowDown::after {
  display: inline-block;
  line-height: 1em;
  font-family: 'spark-icon-fill', sans-serif;

  font-size: 1em;
  vertical-align: baseline;
  font-weight: normal;
  content: '\F10F';
  margin-left: 5px;
}
.Currency__arrowUp::after {
  display: inline-block;
  line-height: 1em;
  font-family: 'spark-icon-fill', sans-serif;

  font-size: 1em;
  vertical-align: baseline;
  font-weight: normal;
  content: '\F112';
  margin-left: 5px;
}
