.Room__select_container {
  position: relative;
  display: flex;
  margin: 0 20px 15px 20px;
}

.Room__select_buttons {
  box-sizing: content-box;
  margin: 5px 0;
  display: flex;
  overflow: hidden;
  /* hide scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Room__select_buttons::-webkit-scrollbar {
  display: none;
}

/* make scrollable on tablet/mobile */
@media (max-width: 991px) {
  .Room__select_buttons {
    overflow: auto;
  }

  .Room__select_container {
    margin: 0 0 15px 0;
  }
}
