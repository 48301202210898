.BookingActionButtons__container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.BookingActionButtons__container > button:focus {
  border-bottom: 1px solid black;
}
.BookingActionButtons__container > button {
  font-weight: 600;
}
