.PasswordInfo__tooltip {
  z-index: 1051;
}
.PasswordInfo__closeToolTipIcon {
  cursor: pointer;
}

.PasswordInfo__infoButton {
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 9999px;
  font-size: 10px;
  border: 1px solid #b9b9b9;
  color: #b9b9b9;
  cursor: pointer;
}
.PasswordInfo__infoButton:hover {
  background-color: #b9b9b9;
  color: white;
}
