.SignInForm__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 30px 30px 30px;
}
.SignInForm__line {
  height: 5px;
  width: 100%;
  color: black;
  opacity: 1;
}
.SignInForm__header {
  font-size: 30px;
}
.SignInForm__smallHeader {
  font-size: 26px;
  text-align: center;
}

.SignInForm__dottedLine {
  width: 100%;
  border-top: dotted 1px #dbd9d9;
}
.SignInForm__bold {
  font-weight: 600;
  font-size: 14px;
  padding: 40px 0 0 0;
  text-transform: uppercase;
}
.SignInForm__textButton {
  margin-bottom: -2px;
  border-bottom: 1px solid black;
}

.SignInForm__input {
  border-bottom: 1px solid black;
  padding-bottom: 15px;
  margin-top: 0px;
}
.SignInForm__label {
  margin-bottom: 5px;
  font-weight: 600;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}
.SignInForm__overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.6;
  z-index: 5;
}
