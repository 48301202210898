.BookingConfirmationMessage__rowContainer {
  padding: 20px;
  margin-bottom: 1.5rem;
  border: 1px solid #dddddd;
  font-weight: 300;
}
.BookingConfirmationMessage__borderColor {
  border-color: #55a04b;
}

.BookingConfirmationMessage__message {
  font-size: 14px;
  font-weight: 600;
}
.BookingConfirmationMessage__orderedMessage {
  font-size: 1.875rem;
  font-weight: 500;
  letter-spacing: 1.7px;
  color: #827808;
}
.BookingConfirmationMessage__messageEmail {
  font-weight: 400;
  font-size: 13px;
  margin-top: 3px;
  letter-spacing: 0.5px;
}
.BookingConfirmationMessage__cancelledMessage {
  color: #d0011b;
  text-transform: uppercase;
  font-size: 14px;
}
