.loginItems__wrapper {
  display: flex;
  flex-direction: column;
  width: 100px;
}
.loginItems__wrapper > button {
  margin-bottom: 1rem;
}
.GuestLogin__loginHeader {
  letter-spacing: 1.7px;
  pointer-events: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.GuestLogin__loginButton {
  background: none;
  border: none;
  white-space: nowrap;
  cursor: pointer;
  text-align: left;
}
@media (max-width: 1199px) {
  .GuestLogin__loginButton {
    font-weight: 600;
  }
}
.GuestLogin__loginButton:focus {
  outline: 2px solid black;
  border-radius: 2px;
}
.GuestLogin__mobileLoginButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.GuestLogin__mobileLoginButtons > div {
  font-weight: 600;
}
.GuestLogin__mobileLoginButtons > button {
  text-transform: uppercase;
}
.GuestLogin__mobileProfileButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.GuestLogin__mobileProfileButtons > button {
  margin-top: 18px;
  text-transform: uppercase;
}
