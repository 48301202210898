.RangeDatePicker__nav_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 12px;
  cursor: pointer;
  background-color: white;
  border: none;
}
