.payment {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 0px !important;
  min-height: 400px;
}

.payment > .adyen-checkout__dropin {
  width: 100%;
  gap: 0 !important;
}

.adyen-checkout__card__holderName__input::placeholder {
  color: #707070;
  opacity: 1;
  font-weight: 300 !important;
}

.adyen-checkout__input:active,
.adyen-checkout__input:active:hover,
.adyen-checkout__input:focus,
.adyen-checkout__input:focus:hover {
  border: 0px;
  box-shadow: none;
}

.adyen-checkout__input-wrapper {
  border: 1px solid #dbd9d9;
  border-radius: 0px;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  line-height: 1.5rem;
}

.adyen-checkout__input-wrapper:hover {
  border-bottom: 1px solid #dbd9d9;
}

.adyen-checkout__button:active {
  background: #757575;
}

.adyen-checkout__label__text {
  font-size: 13px;
  padding-bottom: 10px;
}

.adyen-checkout__input {
  border: 0;
}

.adyen-checkout__field--error .adyen-checkout__error-text {
  color: white;
  background-color: #ac0000;
  padding: 5px;
}

.adyen-checkout__payment-method__radio {
  background-color: white;
  border: 2px solid black;
  width: 26px;
  height: 26px;
}

.adyen-checkout__payment-method__radio:after {
  background-color: #03d386;
  width: 16px;
  height: 16px;
}

.adyen-checkout__payment-method__name {
  font-size: 20px;
}

.adyen-checkout__payment-method--standalone
  .adyen-checkout__payment-method__header {
  padding-top: 12px;
}

.adyen-checkout__dropin--loading {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

.adyen-checkout-payment-methods-list-label {
  margin-top: 20px;
}
