.FeedbackModal__modalWrapper {
  max-width: 1000px;
  margin: 80px auto;
  border-radius: 0;
}

@media (max-width: 768px) {
  .FeedbackModal__modalWrapper {
    height: 86dvh;
  }
}

.FeedbackModal__closeButton {
  position: absolute;
  right: 30px;
  top: 30px;
}

.BookingOverviewModal__modalBody {
  padding: 30px;
}

.BookingOverviewModal__modalView {
  display: flex;
  flex-direction: column;
  background-color: #faf9f7;
  padding: 20px;
}

.BookingOverviewModal__header {
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: 20px;
  text-align: center;
}

.BookingOverviewModal__bookingRow {
  margin-bottom: 20px;
  border: 1px solid #dbd9d9;
  --bs-gutter-x: 0;
}

.BookingOverviewModal__bookingInfo {
  padding: 20px;
  background-color: white;
}

.BookingOverviewModal__roomTitle {
  font-weight: 600;
  font-size: 14px;
}

.BookingOverviewModal__roomCount {
  font-weight: 300;
  font-size: 12px;
}
