.SocialLoginButtons__container {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
}
.SocialLoginButtons__icon {
  width: 24;
  height: 24;
  cursor: pointer;
  margin: 2px;
  border-radius: 9999;
}
.SocialLoginButtons__iconBorder:focus {
  border: 1px solid black;
  border-radius: 50%;
}
.SocialLoginButtons__terms {
  text-align: center;
  margin: 10px 0;
}
.SocialLoginButtons__terms > a {
  color: black;
}
