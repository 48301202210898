.RoomTypeModal__closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
}
.RoomTypeModal__modalWrapper {
  max-width: 1280px;
  height: 85dvh;
  margin: 20px auto 0 auto;
}
