.TaxesAndFees__priceRow {
  padding-top: 15px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  border: none;
}
.TaxesAndFees__dottedHr {
  border-top: 1px dashed #a2a2a2;
  width: 100%;
  margin: 15px 0 0 0;
  background-color: transparent;
}
