.Dates__dates {
  font-weight: 600;
  display: flex;
}
.Dates__endDate {
  margin-left: 5px;
}
@media (max-width: 420px) {
  .Dates__dates {
    flex-direction: column;
  }
  .Dates__endDate {
    margin-left: 0px;
  }
}
