.PaymentSwitcher__paymentContainer {
  border: 1px solid #dbd9d9;
  padding: 20px;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
}
.PaymentSwitcher__title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.PaymentSwitcher__infoText {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 20px;
}
.PaymentSwitcher__selectPaymentOptionButton {
  color: black;
  width: 100%;
  border: 1px solid black;
  height: 45px;
  font-size: 14px;
  text-transform: uppercase;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.PaymentSwitcher__selectPaymentOptionButton:hover {
  color: white;
  background-color: black;
}
