.GuestsField__dropdownTitle {
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 0 20px;
  text-transform: uppercase;
}

@media (max-width: 990px) {
  .GuestField__container {
    padding-top: 5px;
    width: auto;
  }
  .GuestField__container > div > button {
    margin: 15px 0 0 0;
  }
  .GuestField__childrenSelectContainer {
    margin: 10px 0;
  }
}
.GuestsField__childrenError {
  color: #d0011b;
  font-weight: 400;
  margin: 0px 0 10px 0;
}
