.CalendarLegend__container {
  border: 1px solid #ebebeb;
  padding: 10px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.CalendarLegend__checkOut {
  width: 25px;
  height: 25px;
  clip-path: polygon(100% 0, 100% 100%, 50% 50%);
  background-color: #f6f4f0;
  inset: 0;
  margin-right: 10px;
}

.CalendarLegend__checkIn {
  width: 25px;
  height: 25px;
  clip-path: polygon(0 0, 0% 100%, 50% 50%);
  background-color: #f6f4f0;
  inset: 0;
}

.CalendarLegend__unavailable {
  width: 18px;
  height: 18px;
  background-color: #f6f4f0;
  margin-right: 10px;
}

.CalendarLegend__currency {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
}
