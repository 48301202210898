.MultiRoomPicker__roomButtonsContainer {
  display: flex;
  flex: 1;
  overflow: hidden;
  padding-bottom: 20px;
  gap: 15px;
}

.MultiRoomPicker__bedIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: 100%;
  background-color: #f6f4f0;
}

.MultiRoomPicker__roomButton {
  padding: 0;
  z-index: 1;
  height: 100%;
  min-height: 84px;
  max-height: fit-content;
  background: #fff;
  box-sizing: border-box;
  border: 0;
}

.MultiRoomPicker__roomButtonSelected {
  position: relative;
}

.MultiRoomPicker__leftArrow {
  background-color: white;
  height: 100%;
  border: none;
  width: 40px;
  padding-left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 0px 10px -5px rgba(0, 0, 0, 0.3);
  clip-path: inset(0 -100% 0 0);
  z-index: 2;
}

.MultiRoomPicker__rightArrow {
  background-color: white;
  height: 100%;
  width: 40px;
  padding-right: 0;
  border: none;
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -3px 0px 10px -5px rgba(0, 0, 0, 0.3);
  clip-path: inset(0 0 0 -100%);
  z-index: 2;
}
