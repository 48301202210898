.Room__room {
  background-color: white;
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: 400;
  letter-spacing: 0.5px;
  z-index: 2;
}

.Room_info {
  padding: 20px 0 0 0;
}

.Room__slider {
  width: 390px;
  height: 380px;
  top: -40px;
  position: absolute;
}

@media (max-width: 767px) {
  .Room__slider {
    position: relative;
    width: 100%;
    margin-bottom: -40px;
  }
}

.Room__title {
  font-size: 32px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin: 0 20px 10px 20px;
}

.Room__roomDetails {
  margin: 0 20px 10px 20px;
}

.Room__roomDetails > span,
.Room__roomDetailsModal > span {
  margin-right: 8px;
  display: inline-block;
}

.Room__roomDetails > span:not(:last-child)::after,
.Room__roomDetailsModal > span:not(:last-child)::after {
  content: '|';
  margin-left: 8px;
}

.Room__listGridContainer {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin: 0 20px;
}

.Room__listGridColumn {
  flex: 1;
  padding-left: 20px;
}

.Room__listGridColumn > li {
  margin-bottom: 5px;
}

.Room_info > button {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.75;
  letter-spacing: 1px;
}
