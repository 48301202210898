.Picker__dropdownMenu {
  margin-top: 10px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
  padding: 20px;
  z-index: 1050;
  background-color: white;
  position: relative;
}
.Picker__dropdownToggle:focus {
  outline: 1px solid black;
}
.Picker__dropdownMenu:before {
  position: absolute;
  display: inline-block;
  content: '';
  border: 8px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.15);
  top: -16px;
  right: 45%;
}
.Picker__dropdownMenu:after {
  position: absolute;
  display: inline-block;
  content: '';
  border: 7px solid transparent;
  border-bottom-color: #fff;
  top: -14px;
  right: 45.5%;
}
