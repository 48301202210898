.BelmondSingleHotel__cardWrapper {
  width: 100%;
  background-color: #faf9f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BelmondSingleHotel__maintenanceMode {
  justify-content: flex-start;
}

.BelmondSingleHotel__imageWrapper {
  position: relative;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Light grey background for the image area */
}

.BelmondSingleHotel__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.BelmondSingleHotel__skeleton {
  display: flex;
  width: 100%;
  height: 100%;
}

.BelmondSingleHotel__skeleton > span {
  border-radius: 0;
}

.BelmondSingleHotel__fallbackImage {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  /* invert the image colors */
  filter: invert(1);
}

.BelmondSingleHotel__maintenanceLabel {
  position: absolute;
  left: 0;
  bottom: 20px;
  background-color: red;
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
}

.BelmondSingleHotel__title {
  margin: 15px;
}

.BelmondSingleHotel__regionName {
  font-weight: 400;
  color: #757575;
  margin-top: -15px;
}

.BelmondSingleHotel__lowerPart {
  display: flex;
  margin: 15px;
  align-items: center;
  justify-content: space-between;
}

.BelmondSingleHotel__ratePrice {
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
}
