.GuestDetailsForm__prefixGroup {
  display: flex;
}

.GuestDetailsForm__prefixGroup > div {
  width: 100%;
}

.GuestDetailsForm__required {
  color: #757575;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1;
  font-weight: normal;
  vertical-align: middle;
}

.GuestDetailsForm__confirmationMessage {
  font-weight: 300;
  color: #666666;
  font-size: 0.85rem;
}
@media (max-width: 767px) {
  .GuestDetailsForm__confirmationMessage {
    padding-bottom: 20px;
  }
}
