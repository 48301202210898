.BookingsConfirmation__container {
  border: 1px solid #dbd9d9;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 800px) {
  .BookingsConfirmation__container {
    display: flex;
    justify-content: center;
    border: 1px solid #dbd9d9;
    padding: 20px;
  }
}
.BookingsConfirmation__cancelled {
  background-color: #f5f5f5;
}
