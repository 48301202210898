.Accordian__ButtonContainer {
  margin-top: 10px;
  border: 0;
  background: transparent;
  border-bottom: 1px dashed #ebebeb;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
