.MobileBar__mobileBar {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  background-color: #f6f4f0;
  height: 70px;
  -webkit-filter: drop-shadow(0px -4px 4px #00000012);
}
.MobileBar__disabledOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(242, 235, 235, 0.5);
  pointer-events: none;
  z-index: 5;
}
.MobileBar__modalWrapper {
  max-width: 1280px;
  height: 85dvh;
  padding: 5px;
  margin: 0 8px;
  background-color: #faf9f7;
  border-radius: 15px;
}
.MobileBar__mobilePrice {
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 0 20px;
  background-color: #f6f4f0;
  font-weight: 600;
  height: 70px;
  outline: none;
  border: none;
}
