.SortOptions__container {
  display: flex;
  width: 100%;
}

.SortOptions__filterLabel {
  font-size: 14px;
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: 600;
  text-wrap: nowrap;
}

.SortOptions__select {
  background-color: white;
  margin: 0 20px 0 0;
  height: 26px;
  width: 100%;
  max-width: 200px;
  min-width: 80px;
  padding: 0 30px 0 0;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid black;
  color: black;
}

.SortOptions__select:focus {
  box-shadow: none !important;
  border-bottom: 2px solid black;
}

.SortOptions__sortBy {
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.SortOptions__inputContainer {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .SortOptions__sortBy {
    padding-left: 0px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 20px 15px;
    width: 100%;
  }
  .SortOptions__inputContainer {
    flex-direction: column;
    align-items: flex-start;
    width: 82%;
  }
  .SortOptions__select {
    margin: 0;
  }
}
