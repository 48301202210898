.AllStaysIncludedBox__container {
  display: flex;
  flex-direction: column;
  border: 1px solid #d7d7d7;
  padding: 20px;
  background-color: white;
}
.AllStaysIncludedBox__sectionHeader {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
.AllStaysIncludedBox__sectionDescription {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}
