.LoadingSpinner__container {
  --uib-size: 40px;
  --uib-color: black;
  --uib-speed: 1s;
  --uib-stroke: 3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
}

.LoadingSpinner__line {
  position: absolute;
  top: 0;
  left: calc(50% - var(--uib-stroke) / 2);
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: var(--uib-stroke);
}

.LoadingSpinner__line::before {
  content: '';
  height: 22%;
  width: 100%;
  border-radius: calc(var(--uib-stroke) / 2);
  background-color: var(--uib-color);
  animation: pulse calc(var(--uib-speed)) ease-in-out infinite;
  transition: background-color 0.3s ease;
  transform-origin: center bottom;
}

.LoadingSpinner__line:nth-child(1) {
  transform: rotate(calc(360deg / -12 * 1));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 1);
  }
}
.LoadingSpinner__line:nth-child(2) {
  transform: rotate(calc(360deg / -12 * 2));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 2);
  }
}
.LoadingSpinner__line:nth-child(3) {
  transform: rotate(calc(360deg / -12 * 3));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 3);
  }
}
.LoadingSpinner__line:nth-child(4) {
  transform: rotate(calc(360deg / -12 * 4));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 4);
  }
}
.LoadingSpinner__line:nth-child(5) {
  transform: rotate(calc(360deg / -12 * 5));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 5);
  }
}
.LoadingSpinner__line:nth-child(6) {
  transform: rotate(calc(360deg / -12 * 6));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 6);
  }
}
.LoadingSpinner__line:nth-child(7) {
  transform: rotate(calc(360deg / -12 * 7));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 7);
  }
}
.LoadingSpinner__line:nth-child(8) {
  transform: rotate(calc(360deg / -12 * 8));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 8);
  }
}
.LoadingSpinner__line:nth-child(9) {
  transform: rotate(calc(360deg / -12 * 9));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 9);
  }
}
.LoadingSpinner__line:nth-child(10) {
  transform: rotate(calc(360deg / -12 * 10));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 10);
  }
}
.LoadingSpinner__line:nth-child(11) {
  transform: rotate(calc(360deg / -12 * 11));

  &::before {
    animation-delay: calc(var(--uib-speed) / -12 * 11);
  }
}

@keyframes pulse {
  0%,
  80%,
  100% {
    transform: scaleY(0.75);
    opacity: 0;
  }
  20% {
    transform: scaleY(1);
    opacity: 1;
  }
}
