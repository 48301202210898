.Header__headerList li a {
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  font-size: 12px;
  letter-spacing: 1.7px;
}
@media (max-width: 1000px) {
  .Header__currencies > div > div > div {
    font-weight: 500;
  }
}
.Header__language {
  display: flex;
  margin-left: -4px;
}

.Header__list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.Header__list > li {
  padding: 0.75rem 1rem;
}

.Header__list > li > a {
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
}
