
.DisplayOptionsPanel__container {
  margin: 7px 0px 7px 7px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #979797 !important;
}

@media (max-width: 750px) {
  .DisplayOptionsPanel__container {
    margin: 0px 10px;
    padding: 0px;
  }
}
