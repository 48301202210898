.AddonsListItem__container {
  background-color: #faf9f7;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}
.AddonsListItem__imageContainer {
  height: 180px;
  width: 100%;
  position: relative;
}
.AddonsListItem__addonImage {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.AddonsListItem__name {
  font-size: 26px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.AddonsListItem__title {
  padding: 15px 26px 0px 26px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  height: 100%;
}
.AddonsListItem__bottomPart {
  padding: 16px 26px;
}
.AddonsListItem__description {
  line-height: 22px;
}
@media (min-width: 768px) {
  .AddonsListItem__description {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
.AddonsListItem__priceContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  gap: 5px;
}
.AddonsListItem__price {
  font-size: 26px;
  padding-left: 12px;
}
.AddonsListItem__addonAdded {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
