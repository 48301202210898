.AddToCalendarModal__modalWrapper {
  max-width: 530px;
  border-radius: 0px !important;
  height: 85dvh;
  margin: 20px auto 0 auto;
}

.AddToCalendarModal__closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
}
.AddToCalendarModal__modalBody {
  margin: 15px 15px 0 15px;
}
.AddToCalendarModal__inputCheckbox {
  flex-shrink: 0;
  border-radius: 0px !important;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 25px;
  border-color: #979797;
  transform: rotate(45deg);
}

.AddToCalendarModal__inputCheckbox:focus,
.AddToCalendarModal__inputCheckbox::selection {
  background-color: #fff;
  border: 2px solid #776e6e;
  box-shadow: none;
}

.AddToCalendarModal__inputCheckbox:checked {
  background-image: none !important;
  background-color: #fff;
  border: 1px solid #979797;
}

.AddToCalendarModal__inputCheckbox:checked::after {
  content: '';
  position: absolute;
  transition: 'fade-in';
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  background-color: black;
}
.AddToCalendarModal__inputCheckbox:checked:focus {
  background-color: #fff;
  border: 1px solid #555252;
  box-shadow: none;
}
