.PasswordReset__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 40px 40px 35px;
}

.PasswordReset__line {
  height: 5px;
  width: 100%;
  color: black;
  opacity: 1;
}
.PasswordReset__header {
  font-size: 26px;
}
.PasswordReset__input {
  border-bottom: 1px solid black;
  padding-bottom: 15px;
  margin-top: 0px;
}

.PasswordReset__label {
  margin-bottom: 5px;
  font-weight: 600;
  letter-spacing: 0.7px;
}
