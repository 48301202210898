.SearchRoomAlert__container {
  padding: 15px 10px;
  margin-bottom: 10px;
  border: 1px solid #dddddd;
  font-weight: 300;
  letter-spacing: 0.7px;
  display: flex;
  align-items: center;
}
.SearchRoomAlert__title {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.SearchRoomAlert__description {
  font-weight: 400;
}
.SearchRoomAlert__icon {
  margin-left: 10px;
  margin-right: 15px;
}
.success {
  border-color: #55a04b;
}
.info {
  border-color: #827808;
}
.warning {
  border-color: #d0011b;
}
