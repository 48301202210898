.Header__headerList li {
  display: inline-block;
  margin-left: 30px;
}
.Header__phoneLink {
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  margin-right: 30px;
  text-wrap: nowrap;
}

.Header__logoPosition {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.Header__headerLogo {
  height: auto;
  max-height: 70px;
  max-width: 300px;
  overflow-x: hidden;
}

.Header__headerContainer {
  padding: 20px 0;
}
@media (max-width: 600px) {
  .Header__headerLogo {
    height: auto;
    max-height: 40px;
    max-width: 200px;
    overflow-x: hidden;
  }
  .Header__logoPosition {
    display: flex;
    justify-content: center;
    height: 45px;
  }
  .Header__headerContainer {
    padding: 15px 0;
  }
}

.Header__hamburger::before {
  line-height: 1em;
  font-family: 'spark-icon-line', sans-serif;
  font-size: 25px;
  font-weight: normal;
  content: '\F146';
  cursor: pointer;
}

.Header__mobileLogo {
  height: auto;
  max-height: 40px;
  max-width: 200px;
  overflow-x: hidden;
  margin: 20px 0;
}
