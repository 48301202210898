.BookingConfirmationDetails__title {
  display: flex;
  align-items: flex-end;
  font-size: 32px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.BookingConfirmationDetails__roomNumber {
  font-size: 12px;
  margin: 0 0px 10px 12px;
  color: #757575;
  text-transform: none;
  white-space: nowrap;
}

.BookingConfirmationDetails__dottedHr {
  border-top: 1px dashed #a2a2a2;
  width: 100%;
  background-color: transparent;
}
.BookingConfirmationDetails__priceContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  font-weight: 600;
}
.BookingConfirmationDetails__bookingReference {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}
.BookingConfirmationDetails__cancelled {
  color: #d0011b;
  text-transform: uppercase;
  margin-left: 8px;
  font-size: 14px;
}
.BookingConfirmationDetails__accomodationDetails {
  display: flex;
  cursor: pointer;
  max-width: 220px;
}
.BookingConfirmationDetails__accomodationDetails > div > span {
  font-weight: 600;
}
