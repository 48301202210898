.Footer__bbeFooter svg {
  margin-left: 15px;
}

.Footer__footerText {
  font-size: 10px;
  font-weight: 300;
  margin-top: 40px;
  line-height: 2;
  color: #757575;
}

.Footer__linksList a {
  font-weight: 300;
}

.Footer__bbeFooter a {
  color: black;
}

.Footer__bbeFooter ul {
  list-style: none;
  padding-left: 0;
}

.Footer__bbeFooter li {
  line-height: 2.7rem;
}
.Contact__ul > li > span {
  letter-spacing: 0.7px;
  font-weight: 600;
}
.ExploreOurWorld__ul > li > a,
.JoinOurFamilyList__ul > li > a {
  text-decoration: none;
}
.icon {
  width: 15px;
  height: 15px;
  margin: 0px 0px 3px 4px !important;
}
.Footer__shareButton {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 3px;
  position: relative;
  text-transform: uppercase;
}
.Footer__shareButton:hover:after {
  width: 0;
  left: 0;
}
.Footer__shareButton:after {
  background: none repeat scroll 0 0 black;
  bottom: -2px;
  content: '';
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  transition: width 0.4s ease 0s, left 0.5s ease 0s;
  width: 100%;
}
