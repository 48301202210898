.SkeletonLoading__container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
.SkeletonLoading__leftColumn {
  width: 390px;
  top: -40px;
  position: absolute;
}
@media (max-width: 767px) {
  .SkeletonLoading__leftColumn {
    position: relative;
    width: 100%;
    margin-bottom: -40px;
  }
}
.SkeletonLoading__rightColumn {
  padding-left: 0 !important;
  margin-bottom: 60px;
  z-index: 2;
}
.SkeletonLoading__infoContainer {
  height: 450px;
  border-radius: 0.25rem;
  background-color: white;
  padding: 20px;
}

@media (max-width: 767px) {
  .SkeletonLoading__button {
    width: 50%;
  }
}
